import React, { useState } from 'react';
import { Marker, Popup } from 'react-leaflet';

import HousePopup from './housePopup';

// Icons
import HomeIcon from '../../../images/home.svg';
import RedHomeIcon from '../../../images/red-home.svg';
import GreenHomeIcon from '../../../images/green-home.svg';
import BlueHomeIcon from '../../../images/blue-home.svg';

const IconSize = 25;
const PopupAnchorY = -18;
const IconAnchorX = 12.5;
const IconAnchorY = 25;

function HouseMarker({ data, ids, names, users, multiSelect, setSelected }) {
	const [assigned, setAssigned] = useState({
		id: data.id,
		firstName: data.firstName,
		lastName: data.lastName,
		assignedUser: data.assignedUser,
		streetName: data.streetName,
		description: data.description,
		notes: data.notes,
		lat: data.lat,
		lon: data.lon,
		confirmed: data.confirmed,
		completed: data.completed,
	});

	const L = require('leaflet');
	let myIcon;

	if (assigned.assignedUser === 0) {
		myIcon = L.icon({
			iconUrl: HomeIcon,
			iconRetinaUrl: HomeIcon,
			iconAnchor: [IconAnchorX, IconAnchorY],
			popupAnchor: [0, PopupAnchorY],
			iconSize: [IconSize, IconSize],
		});
	} else if (assigned.completed == true) {
		myIcon = L.icon({
			iconUrl: GreenHomeIcon,
			iconRetinaUrl: GreenHomeIcon,
			iconAnchor: [IconAnchorX, IconAnchorY],
			popupAnchor: [0, PopupAnchorY],
			iconSize: [IconSize, IconSize],
		});
	} else if (assigned.confirmed == true) {
		myIcon = L.icon({
			iconUrl: BlueHomeIcon,
			iconRetinaUrl: BlueHomeIcon,
			iconAnchor: [IconAnchorX, IconAnchorY],
			popupAnchor: [0, PopupAnchorY],
			iconSize: [IconSize, IconSize],
		});
	} else {
		myIcon = L.icon({
			iconUrl: RedHomeIcon,
			iconRetinaUrl: RedHomeIcon,
			iconAnchor: [IconAnchorX, IconAnchorY],
			popupAnchor: [0, PopupAnchorY],
			iconSize: [IconSize, IconSize],
		});
	}

	const placeHolder = (e) => {
		if (multiSelect) {
			// console.log(data.id)
			setSelected(data.id)
		}
		// console.log(e)
	}

	const popup = (
		<Popup minWidth={150} maxWidth={200}>
			<HousePopup assigned={assigned} setAssigned={setAssigned} ids={ids} names={names} users={users} />
		</Popup>
	);

	return (
		<Marker 
			position={[assigned.lat, assigned.lon]} 
			icon={myIcon}
			eventHandlers={{
				click: placeHolder
			}}
		>
			{ multiSelect ? <></> : popup }
		</Marker>
	);
}

export default HouseMarker;
