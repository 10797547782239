import React, { useState } from 'react';
import { Marker, Popup } from 'react-leaflet';

import PersonPopup from './personPopup';

// Icons
import WalkIcon from '../../../images/person.svg';

const IconSize = 25;
const PopupAnchorY = -18;
const IconAnchorX = 12.5;
const IconAnchorY = 25;

function PersonMarker({ data }) {
	const [assigned, setAssigned] = useState({
		firstName: data.firstName,
		lastName: data.lastName,
		id: data.id,
		lat: data.lat,
		lon: data.lon,
	});

	const L = require('leaflet');
	let myIcon = L.icon({
		iconUrl: WalkIcon,
		iconRetinaUrl: WalkIcon,
		iconAnchor: [IconAnchorX, IconAnchorY],
		popupAnchor: [0, PopupAnchorY],
		iconSize: [IconSize, IconSize],
	});

	return (
		<Marker position={[assigned.lat, assigned.lon]} icon={myIcon}>
			<Popup minWidth={175} maxWidth={175}>
				<PersonPopup assigned={assigned} setAssigned={setAssigned} />
			</Popup>
		</Marker>
	);
}

export default PersonMarker;
