import React, { useState, useEffect } from 'react';
import { message, Input, AutoComplete } from 'antd';
import Cookies from 'universal-cookie';

function HousePopup({ assigned, setAssigned, ids, names, users }) {
	const updateClient = window.APIURL + '/customers/update-customer-details';
	const [showList, setShowList] = useState(false);
	const [autoCompleteSelection, setAutoCompleteSelection] = useState([0, 'Unassigned'])
	const { TextArea } = Input;

	const handleChange = (event) => {
		event.preventDefault();
		setShowList(true);
	};

	const handleAssign = (event) => {
		event.preventDefault();
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');

		const data = new FormData(event.target);
		if (assigned.assignedUser != parseInt(autoCompleteSelection[0])) {
			setAssigned({
				id: assigned.id,
				firstName: assigned.firstName,
				lastName: assigned.lastName,
				assignedUser: parseInt(autoCompleteSelection[0]),
				streetName: assigned.streetName,
				description: data.get('description'),
				notes: data.get('notes'),
				lat: assigned.lat,
				lon: assigned.lon,
				confirmed: false,
				completed: false,
			});
		} else {
			setAssigned({
				id: assigned.id,
				firstName: assigned.firstName,
				lastName: assigned.lastName,
				assignedUser: parseInt(autoCompleteSelection[0]),
				streetName: assigned.streetName,
				description: data.get('description'),
				notes: data.get('notes'),
				lat: assigned.lat,
				lon: assigned.lon,
				confirmed: assigned.confirmed,
				completed: assigned.completed,
			});
		}

		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				id: assigned.id,
				assignedUser: autoCompleteSelection[0],
				description: data.get('description'),
				notes: data.get('notes'),
			}),
		};

		fetch(updateClient, requestOptions)
			.then((response) => response.json())
			.then((data) => {

				if (data.hasOwnProperty('message') && data.message.includes('Success')) {
					message.success('User successfully updated.');
				} else {
					message.error('An error occured while updating user.');
				}
			});
		setShowList(false);
	};
	if (showList) {
		return (
			<div className='popup-entry'>
				<form className='popup-entry-form' onSubmit={handleAssign}>
				<p className='message'>
						<a style={{ fontSize: 17, fontWeight: 700 }}>{assigned.firstName + ((assigned.lastName != '-') ? (" " + assigned.lastName) : "")}</a>
						<br/>
						<a style={{ fontSize: 15, fontWeight: 700 }}>{assigned.streetName}</a>
					</p>
					<p className='message' style={{marginBottom: '2px'}}>
						<b>Assigned To:</b>
					</p>
					<AutoComplete
						style={{
							width: 145,
						}}
						options={users}
						placeholder={'Type the name here'}
						filterOption={(inputValue, option) =>
							option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
						}
						onSelect={(inputValue, option) => {
							users.find((item, i) => {
								if(item.value === option.value){
									setAutoCompleteSelection([ids[i], option.value])
									return;
								}
							});
						}}
					/>
					<p className='message' style={{marginBottom: '2px'}}><b>Description:</b></p>
					<TextArea 
						id='description' 
						name='description'
						defaultValue={assigned.description || ''}
						autoSize 
						style={{ padding: '5px 10px 5px 10px' }}
						required 
					/>
					<p className='message' style={{marginBottom: '2px'}}><b>Notes:</b></p>
					<TextArea 
						id='notes' 
						name='notes'
						defaultValue={assigned.notes || ''}
						autoSize 
						style={{ padding: '5px 10px 5px 10px' }}
						required 
					/>
					<button>Update</button>
				</form>
			</div>
		);
	} else {
		return (
			<div className='popup-entry'>
				<form className='popup-entry-form' onSubmit={handleChange}>
					<p className='message'>
					<a style={{ fontSize: 17, fontWeight: 700 }}>{assigned.firstName + ((assigned.lastName != '-') ? (" " + assigned.lastName) : "")}</a>
						<br/>
						<a style={{ fontSize: 15, fontWeight: 700 }}>{assigned.streetName}</a>
					</p>
					<p className='message'>
						<b>Assigned To:</b> 
						<br/>
						<a>{names[ids.indexOf(assigned.assignedUser)]}</a>
					</p>
					<p className='message'>
						<b>Description:</b>
						<br />
						<a>{assigned.description}</a>
					</p>
					<p className='message'>
						<b>Special Notes:</b>
						<br />
						<a>{assigned.notes}</a>
					</p>
					<button>Edit Details</button>
				</form>
			</div>
		);
	}
}

export default HousePopup;
