import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/css/bootstrap.css"
import "simple-line-icons/css/simple-line-icons.css"
import "@fortawesome/fontawesome-free/css/all.css"


const BaseURL = 'https://api.shovelledportal.ca/'; 
const Location = 'winnipeg'; // Prod: city name
window.APIURL = BaseURL + Location;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
