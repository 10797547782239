import React from 'react';

function PersonPopup({ assigned }) {

	return (
		<div className='popup-entry'>
			<form className='popup-entry-form'>
				<p className='message'>
					<a style={{ fontSize: 18, fontWeight: 700 }}>{assigned.firstName + ' ' + assigned.lastName}</a>
				</p>
			</form>
		</div>
	);
}

export default PersonPopup;
