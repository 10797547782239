import React from "react";
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import UserCard from './userCard';

const MobileList = ({ data }) => {
    const jobArray = ['Unassigned', 'Contractor', 'Manager', 'Adminstrator'];
    return (
        <List  className='material-list global-item-shadow' dense disablePadding>
            { data.map((item) => (
                <ListItem key={item.id} divider>
                    <ListItemText primary={item.firstName + (item.lastName != '-' ? ' ' + item.lastName : '')} secondary={ jobArray[item.position] } />
                    <ListItemSecondaryAction>
                        <UserCard data={item} key={item.id} />
                    </ListItemSecondaryAction>
                </ListItem>
            )) }
        </List>	
    );
}

export default MobileList;