import React, { useCallback, useMemo, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import { Button, message } from 'antd';
import {useDropzone} from 'react-dropzone';
import '../../../css/DropZone.css';
import Compressor from 'compressorjs';

import Cookies from 'universal-cookie';

import 'antd/dist/antd.css';
import Loading from '../../global/loading';

const MAX_NUMBER_OF_IMAGES = 3

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#aaaaaa',
	borderStyle: 'dashed',
	backgroundColor: '#fcfcfc',
	color: '#aaaaaa',
	transition: 'border .3s ease-in-out'
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

const ConfirmedJobs = ({ jobInfo, setLoading, getData, accountInfo }) => {
	const completeURL = window.APIURL + '/jobs/complete-assigned';
	const imageURL = window.APIURL + '/images/image';
	
	const [files, setFiles] = useState([]);
	const [compressing, setCompressing] = useState(false);

	const getFiles = async () => {
		let data = new FormData();
		data.append('user', accountInfo[1].replaceAll(" ", "_"))
		data.append('address', jobInfo.streetName.replaceAll(" ", "_"))
		files.forEach(file => {
			console.log(file)
			data.append('file', file);
		})
		return data;
	}

	const completeJob = async () => {
		setLoading(true);
		let imagesSubmitted = false;

		if (files.length > 0) {
			let data = await getFiles();
			
			const cookies = new Cookies();
			let cookieToken = await cookies.get('shovelled-token');

			const requestOptions = {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + cookieToken,
				},
				body: data
			};

			const updateInfo = await fetch(imageURL, requestOptions);
			const updateJSON = await updateInfo.json();
			
			console.log(updateJSON)
			if (typeof updateJSON.message != 'undefined' && updateJSON.message.includes('Success')) {
				imagesSubmitted = true;
			}
		} else imagesSubmitted = true;

		if (imagesSubmitted) {
			const cookies = new Cookies();
			let cookieToken = await cookies.get('shovelled-token');
			
			const completeOptions = {
				mode: 'cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',	
					Authorization: 'Bearer ' + cookieToken,
				},
				body: JSON.stringify(jobInfo),
			};

			const completeInfo = await fetch(completeURL, completeOptions);
			const completeJSON = await completeInfo.json();

			if (typeof completeJSON.message != 'undefined' && completeJSON.message.includes('Success')) {		
				message.success('Job completed and images successfully uploaded.');
			} else {
				message.error('An error occured while completing the job. Please contact the administrator.');
			}
		} else {
			message.error('An error occured while uploading the photos. Please contact the administrator.');
		}
		
		await getData();

		setLoading(false);
	};

	
	function compressFile(file) {
		return new Promise((resolve, reject) => {
			new Compressor(file, {
				beforeDraw(context, canvas) {
					context.fillStyle = '#333333';
					context.fillRect(0, 0, canvas.width, canvas.height);
					// context.filter = 'grayscale(100%)';
				},
				quality: 0.5,
				width: 500,
				height: 500,
				resize: "contain",
				// The compression process is asynchronous,
				// which means you have to access the `result` in the `success` hook function.
				success(result) {
					resolve(result)	
				},
				error(err) {
					console.log(err.message);
					message.error("An error occurred while attempting to compress an image.")
					reject(err)
				},
				drew(context, canvas) {
					context.fillStyle = '#fff';
					context.font = '1rem serif';
					context.fillText(accountInfo[1], 20, canvas.height - 20);
				},
			});
		});
	}

	async function compressFiles(files) {
		const promises = files.map(file => compressFile(file))
		const compressedFiles = await Promise.all(promises)
		setFiles(prev => [...prev, ...compressedFiles])
		message.success("Images were compressed successfully.")
	}

	const onDrop = useCallback( async acceptedFiles => {
		setCompressing(true);
		if ((files.length >= MAX_NUMBER_OF_IMAGES) || (acceptedFiles.length > MAX_NUMBER_OF_IMAGES) || ((files.length + acceptedFiles.length) > MAX_NUMBER_OF_IMAGES)) {
			message.error('Too many photos have been added. Please only select a maximum of ' + MAX_NUMBER_OF_IMAGES + '.')
		} else {
			// message.loading('Compressing Images...');
			compressFiles(acceptedFiles)
		}
		setCompressing(false);
    }, [files]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: 'image/*'
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

	const removeFile = file => () => {
		const newFiles = [...files]
		newFiles.splice(newFiles.indexOf(file), 1)
		setFiles(newFiles)
	}

    const uploadedFiles = files.map(file => (
        <div key={file.name} style={{ margin: "5px" }}>
			<span className="dz-file-size">{file.name}</span>
            <div className="dz-file-remove" style={{ marginLeft: "10px" }} onClick={removeFile(file)}>X</div>
        </div>
    ));

	
	const loadingIcon = <Loading />;
	const pageContent = (
		<>
			<h6 style={{ textAlign: 'center' }}>Address:</h6>
			<p>{jobInfo.streetName}</p>
			<h6>Description:</h6>
			<p>{jobInfo.description}</p>
			<h6>Notes:</h6>
			<p>{jobInfo.notes}</p>
			<h6>Property Payout:</h6>
			<p>{"$" + jobInfo.payout}</p>
			<section style={{ margin: "15px" }}>
				<div {...getRootProps({style})}>
					<input {...getInputProps()} />
					<div>Drag + drop some files here, or click to select files</div>
				</div>
				<aside style={{ 
						display: "inline-block", 
						alignItems: "center", 
						justifyContent: "center", 
						textAlign: "center" 
					}}
				>
					{ uploadedFiles }
				</aside>
			</section>
			<Button onClick={completeJob} shape='round' type='primary'>
				Completed
			</Button>
		</>
	);

	return (
		<div>
			{(compressing) ? loadingIcon : pageContent}
		</div>
	);
};

export default ConfirmedJobs;