import React, { useState, useEffect } from 'react';
import { message, Spin, Card, Modal, Button} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';

import Header from '../global/header';
import Loading from '../global/loading';

import 'antd/dist/antd.css';
import '../../css/Profile.css';

const Profile = ({ accountInfo }) => {
	const loadingIcon = <LoadingOutlined style={{ fontSize: 26 }} spin />;
	const passwordURL = window.APIURL + '/accounts/change-password';

	const [loading, setLoading] = useState(true);
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [submitButton, setSubmitButton] = useState('Change Password');
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(async () => {
		setLoading(false);
	}, []);

	const showModal = () => {
		setIsModalVisible(true);
	};
	
	const handleCancel = () => {
		setIsModalVisible(false);
	};

    const handleOk= (e) => {
		e.preventDefault();
		setSubmitButton(<Spin indicator={loadingIcon} />);
		if (newPassword !== confirmNewPassword) {
			message.error('The passwords entered do not match.');
			setSubmitButton('Change Password');
			setIsModalVisible(false);	
			return;
		}
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				id: accountInfo[3],
				oldPassword: currentPassword,
                newPassword: newPassword,
				confirmNewPassword: confirmNewPassword
			}),
		};
		fetch(passwordURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (data.hasOwnProperty('message') && data.message.includes('successfully')) {
					message.success('Password updated successfully.');
					setCurrentPassword("");
					setNewPassword("");
				} else if (data.hasOwnProperty('error') && data.error.includes('Passwords do no match')) {
					message.error('The passwords entered do not match.');					
				} else {
					message.error('An error occured while changing password.');
				}
				setSubmitButton('Change Password');
				setIsModalVisible(false);
			});
	};

	const pageContent = (
        <div className='col-sm-12'>
            <Card className='global-item-shadow' style={{textAlign: 'center' }}>
				<h4 className='text-center'>Account Security</h4>
				<br />
				<Button type="primary" onClick={showModal}>
					Change Password
				</Button>
            </Card>
			<Modal title="Update Password" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText={submitButton}>
				<form className='profile-form'>
					<input type='password' placeholder='Current Password' onChange={(e) => setCurrentPassword(e.target.value)} />
					<input type='password' placeholder='New Password' onChange={(e) => setNewPassword(e.target.value)} />
					<input type='password' placeholder='Confirm New Password' onChange={(e) => setConfirmNewPassword(e.target.value)} />
				</form>
			</Modal>
        </div>
	);

	return (
		<div className='page-content'>
			<Header title='My Profile' searchVisible={false} />
			<div className='pad'>
				{(loading) ? loadingIcon : pageContent}
			</div>
		</div>
	);
};

export default Profile;