import React from 'react';
import { useLocation } from 'react-router-dom'
// Import React Router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import '../../css/Nav.css';
const jobArray = ['Unassigned', 'Contractor', 'Manager', 'Adminstrator'];

const AdminNav = ({ accountInfo, siteTitle, open, setOpen }) => {
	const location = useLocation();
	const contractorMenuItems = (
		<React.Fragment>
			<li className='header-menu'>
				<span>Contractor</span>
			</li>
			<li>
				<Link to='/jobs'>
					<i className={((location.pathname === '/jobs') ? 'selected-menu-item ' : '') + 'fa fa-toolbox'}></i>
					<span className={(location.pathname === '/jobs') ? 'selected-menu-item' : ''}>My Jobs</span>
				</Link>
			</li>
			<li>
				<Link to='/resources'>
					<i className={((location.pathname === '/resources') ? 'selected-menu-item ' : '') + 'fa fa-book-open'}></i>
					<span className={(location.pathname === '/resources') ? 'selected-menu-item' : ''}>Resources</span>
				</Link>
			</li>
		</React.Fragment>
	);

	const managerMenuItems = (
		<React.Fragment>
			{ contractorMenuItems }
			<li className='header-menu'>
				<span>Manager</span>
			</li>
			<li>
				<Link to='/map'>
					<i className={((location.pathname === '/map') ? 'selected-menu-item ' : '') + 'fa fa-map-marked-alt'}></i>
					<span className={(location.pathname === '/map') ? 'selected-menu-item' : ''}>Map</span>
				</Link>
			</li>
			<li>
				<Link to='/analytics'>
					<i className={((location.pathname === '/analytics') ? 'selected-menu-item ' : '') + 'fa fa-chart-line'}></i>
					<span className={(location.pathname === '/analytics') ? 'selected-menu-item' : ''}>Analytics</span>
				</Link>
			</li>
			<li>
				<Link to='/gallery'>
					<i className={((location.pathname === '/gallery') ? 'selected-menu-item ' : '') + 'fa fa-images'}></i>
					<span className={(location.pathname === '/gallery') ? 'selected-menu-item' : ''}>Gallery</span>
				</Link>
			</li>
			<li>
				<Link to='/reset'>
					<i className={((location.pathname === '/reset') ? 'selected-menu-item ' : '') + 'fa fa-undo'}></i>
					<span className={(location.pathname === '/reset') ? 'selected-menu-item' : ''}>Reset Data</span>
				</Link>
			</li>
		</React.Fragment>
	);

	const adminMenuItems = (
		<React.Fragment>
			{ managerMenuItems }
			<li className='header-menu'>
				<span>Admin</span>
			</li>
			<li>
				<Link to='/billing'>
					<i className={((location.pathname === '/billing') ? 'selected-menu-item ' : '') + 'fa fa-file-invoice-dollar'}></i>
					<span className={(location.pathname === '/billing') ? 'selected-menu-item' : ''}>Customer Billing Info</span>
				</Link>
			</li>
			<li>
				<Link to='/users'>
					<i className={((location.pathname === '/users') ? 'selected-menu-item ' : '') + 'fa fa-users'}></i>
					<span className={(location.pathname === '/users') ? 'selected-menu-item' : ''}>Platform Users</span>
				</Link>
			</li>
		</React.Fragment>
	);

	const sidebarBrand = (
		<div className='sidebar-brand'>
			<Link to='/'>Shovelled Portal</Link>
			<div id='close-sidebar' onClick={() => setOpen(!open)}>
				<i className='fas fa-arrow-circle-left'></i>
			</div>
		</div>
	);

	const sidebarMenu = (
		<div className='sidebar-menu'>
			<ul>
				{ accountInfo[2] > 1 ? (accountInfo[2] > 2 ? adminMenuItems : managerMenuItems) : contractorMenuItems }
			</ul>
		</div>
	);

	const sidebarAccount = (
		<Link to='/profile'>
			<div className='sidebar-header'>
				<div className='user-pic'>
					<img
						className='img-responsive img-rounded'
						src='https://raw.githubusercontent.com/azouaoui-med/pro-sidebar-template/gh-pages/src/img/user.jpg'
						alt='user avatar'
					/>
				</div>
				<div className='user-info'>
					<span className='user-name'>{ accountInfo[1] }</span>
					<span className='user-role'>{ jobArray[accountInfo[2]] }</span>
				</div>
			</div>
		</Link>
	);

	const sidebarFooter = (
		<div className='sidebar-footer'>
			<a href='https://www.shovelled.ca/'>Copyright © { siteTitle } 2021</a>
		</div>
	);

	return (
		<nav id='sidebar' className='sidebar-wrapper' style={ document.URL.indexOf("gallery") ? {zIndex: 1001} : {zIndex: 1000} }>
			<div className='sidebar-content'>
				{ sidebarBrand }				
				{ sidebarMenu }
				{ sidebarAccount }
			</div>
			{ sidebarFooter }
		</nav>
	);
};

export default AdminNav;