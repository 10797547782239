import React, { useState, useEffect } from 'react'
import { Card  } from 'antd';
import Cookies from 'universal-cookie';

import Header from '../global/header'
import Loading from '../global/loading';
import IndividualTable from './analytics/individualTable';
import GlobalProgress from './analytics/globalProgress';

import 'antd/dist/antd.css';
import '../../css/Analytics.css'

let clientList = [];
let contractorList = [];

const AnalyticsCharts = () => {
    const getClients = window.APIURL + '/customers/get-all-customers-analytics';
    const getContractors = window.APIURL + '/users/get-all-users-analytics';
    
	const [loading, setLoading] = useState(true);
    const [globalData, setGlobalData] = useState([]);
    const [individualData, setIndividualData] = useState([]);
    const [tabKey, setTabKey] = useState('Global');

	useEffect(async () => {
		setLoading(true);

		clientList = [];
		contractorList = [];

        let totalUnassigned = 0;
        let totalUnconfirmed = 0;
        let totalConfirmed = 0;
        let totalCompleted = 0;
        let total = 0;

		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');

		const requestOptions = {
            mode: 'cors',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
		};

		const contractorInfo = await fetch(getContractors, requestOptions);
		const clientInfo = await fetch(getClients, requestOptions);
		const contractorJSON = await contractorInfo.json();
		const clientJSON = await clientInfo.json();

        let contractorAssignedData = [];
        let count = 0;
		await contractorJSON.forEach((contractor) => {
            let contractorCompleted = 0;
            let contractorConfirmed = 0;
            let contractorUnconfirmed = 0;
            let contractorTotal = 0;
            let contractorPayout = 0;
			contractorList.push(contractor);

            clientJSON.forEach(client => {
                clientList.push(client);

                if (client.assignedUser === contractor.id) {
                    if (client.completed) {
                        contractorCompleted++;
                        contractorPayout += client.payout;
                    } else if (client.confirmed)
                        contractorConfirmed++;
                    else if (client.assignedUser > 0)
                        contractorUnconfirmed++;
                    contractorTotal++;
                }
            });

            let contractorData = {
                "name": contractor.firstName + " " + contractor.lastName,
                "completed": contractorCompleted,
                "confirmed": contractorConfirmed,
                "unconfirmed": contractorUnconfirmed,
                "payout": contractorPayout,
                "total": contractorTotal,
                "key": count++
            }

            contractorAssignedData.push(contractorData);
		});

        clientJSON.forEach(client => {
            if (client.completed)
                totalCompleted++;
            else if (client.confirmed)
                totalConfirmed++;
            else if (client.assignedUser > 0)
                totalUnconfirmed++;
            else totalUnassigned++;
            total++;
        });

        setIndividualData(contractorAssignedData)
        setGlobalData([ totalUnassigned, totalUnconfirmed, totalConfirmed, totalCompleted, total ])
		setLoading(false);
	}, []);

    const tabList = [
        {
          key: 'Global',
          tab: 'Global',
        },
        {
          key: 'Individual',
          tab: 'Individual',
        },
    ];
    
    const contentList = {
        Global: <GlobalProgress data={globalData}/>,
        Individual: <IndividualTable data={individualData}/>,
    };

    const loadingIcon = <Loading />;
    const pageContent = (
        <Card
            className='global-item-shadow'
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={tabKey}
            onTabChange={key => {
                setTabKey(key);
            }}
            >
            { contentList[tabKey] }
        </Card>
    );
	
    return ( 
        <div className='page-content text-center'>
            <Header title="Analytics" searchVisible={false}/>
            <div className='pad'>
                { loading ? loadingIcon : pageContent }
            </div>
        </div>
    );
}
 
export default AnalyticsCharts;