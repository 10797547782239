import React from "react";
import { Table, Card } from 'antd';
import BillingCard from './billingCard';

const FullscreenTable = ({ data }) => {
    const columns = [
        {
            title: 'Address',
            dataIndex: 'streetName',
            fixed: 'left',
            defaultSortOrder: 'ascend',
            width: 150,
            align: 'center',
            key: 1,
            sorter: (a, b) => a.streetName.split(" ")[1].localeCompare(b.streetName.split(" ")[1]),
        },
        {
            title: 'First/Company',
            dataIndex: 'firstName',
            width: 125,
            align: 'center',
            key: 2,
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
		{
            title: 'Last',
            dataIndex: 'lastName',
            width: 125,
            align: 'center',
            key: 3,
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 200,
            align: 'center',
            key: 4,
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'Postal Code',
            dataIndex: 'postalCode',
            width: 200,
            align: 'center',
            key: 5,
            sorter: (a, b) => a.postalCode.localeCompare(b.postalCode),
        },
		{
            title: 'Rate',
            dataIndex: 'rate',
            width: 70,
            align: 'center',
            key: 6,
            sorter: (a, b) => a.rate - b.rate,
			render: text => <a>{'$' + text}</a>,
        }, 
		{
			title: 'Edit',
			key: 'edit',
			width: 80,
            align: 'center',
            key: 7,
			render: text => <BillingCard data={text} key={text.id} />,
		  },
    ];

	const onChange = (pagination, filters, sorter, extra) => {}

    return (
        <Card className='global-item-shadow'>
            <Table 
                columns={columns} 
                dataSource={data} 
                onChange={onChange} 
                scroll={{ x: 350 }}
                bordered={true}
                size="small" 
                rowKey={record => record.id}
                pagination={false}
            />
        </Card>
    );
}

export default FullscreenTable;
