import React, { useState, useEffect } from 'react';
import { Card, Image } from 'antd';
import Cookies from 'universal-cookie';

import Header from '../global/header';
import Loading from '../global/loading';
import GalleryImage from './gallery/galleryImage';

import 'antd/dist/antd.css';
import '../../css/Gallery.css';

let imageDictionary = {};
let returnedData = [];

const Gallery = () => {
	const imageURL = window.APIURL + '/images/get-gallery-image-list';
	const [loading, setLoading] = useState(true);
	const [organize, setOrganize] = useState('address')
	const [pageContent, setPageContent] = useState();

	useEffect(() => {
		imageDictionary = {};
        const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			}
		};
		fetch(imageURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log(data)
				let count = 0;
				data.forEach(element => {
					returnedData.push(element);
					if (organize == 'address') {
						let address = element.split("_A_")[1].split("_F_")[0].replaceAll("_", " ");
						if (imageDictionary[address] == null)
							imageDictionary[address] = [<GalleryImage imgURL={element} key={count++} />]
						else imageDictionary[address].push(<GalleryImage imgURL={element} key={count++} />)
					} else {
						let user = element.split("/")[1].replaceAll("_", " ");
						if (imageDictionary[user] == null)
							imageDictionary[user] = [<GalleryImage imgURL={element} key={count++} />]
						else imageDictionary[user].push(<GalleryImage imgURL={element} key={count++} />)
					}
				});
				let gallery = createGallery();
				if (gallery.length < 1) {
					setPageContent(
						<div className='col-sm-12'>
							<Card className='global-item-shadow' style={{ textAlign: 'center' }}>
								There are currently no images to display.
							</Card>
						</div>
					);
				} else {
					setPageContent(
						<React.Fragment>
							{gallery}
						</React.Fragment>
					);
				}
				setLoading(false);
			});
    }, [])

	const createGallery = () => {
		let result = [];
		let count = 0;
		for(let key in imageDictionary) {
			result.push(
				<Card className='global-item-shadow text-center' key={count++}>
					<div className="gallery-subset" >
						<h2>{key}</h2>
						<Image.PreviewGroup>
							{ imageDictionary[key] }
						</Image.PreviewGroup>
					</div>
				</Card>
			)
		}
		return result;
	}
	
	const searchData = (value) => {
		imageDictionary = {};
		let count = 0;
		returnedData.forEach(element => {
			let user = element.split("/")[1].replaceAll("_", " ");	
			let address = element.split("/")[2].replaceAll("_", " ");
			if (organize == 'address' && (address.search(value) !== -1 || user.search(value) !== -1)) {
				if (imageDictionary[address] == null)
					imageDictionary[address] = [<GalleryImage imgURL={element} key={count++} />]
				else imageDictionary[address].push(<GalleryImage imgURL={element} key={count++} />)
			} else if (address.search(value) !== -1 || user.search(value) !== -1) {
				if (imageDictionary[user] == null)
					imageDictionary[user] = [<GalleryImage imgURL={element} key={count++} />]
				else imageDictionary[user].push(<GalleryImage imgURL={element} key={count++} />)
			}
		});
		
		let gallery = createGallery();
		if (gallery.length < 1) {
			setPageContent(
				<div className='col-sm-12'>
					<Card className='global-item-shadow' style={{ textAlign: 'center' }}>
						There are currently no images to display.
					</Card>
				</div>
			);
		} else {
			setPageContent(
				<React.Fragment>
					{gallery}
				</React.Fragment>
			);
		}
		
	};

	const loadingIcon = <Loading />;

	return (
		<div className='page-content'>
			<Header title="Gallery" searchVisible={true} onChange={(e) => searchData(e.target.value)}/>
			<div className='pad'>
				{ loading ? loadingIcon : pageContent }
			</div>
		</div>
	);
};

export default Gallery;
