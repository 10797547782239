import React, { useState } from 'react';
import { message, Spin, Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Cookies from 'universal-cookie';
import logo from '../../images/shovelled-logo.png'

const Login = ({ setLoggedIn }) => {
	const loginURL = window.APIURL + '/accounts/login';

	const cookies = new Cookies();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loginButton, setLoginButton] = useState('Login');
	const loadingIcon = <LoadingOutlined spin />;

	const attemptLogin = (e) => {
		e.preventDefault();
		setLoginButton(<Spin indicator={loadingIcon} />);
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: username,
				password: password,
			}),
		};
		fetch(loginURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (data.hasOwnProperty('message') && data.message.includes('Success')) {
					message.success('Sign in successful.');
					cookies.set('shovelled-token', data.token);
					setLoggedIn(true);
					window.location.reload();
				} else if (data.hasOwnProperty('error') && data.error.includes('Too many incorrect attempts')) {
					message.error('Too many incorrect attempts. Please wait 30 minutes before trying again.');
				} else {
					message.error('An error occured while signing in.');
				}
				setLoginButton('Login');
			});
	};

	const loginForm = (
		<form className='login-form' onSubmit={attemptLogin}>
			<input type='text' placeholder='username' onChange={(e) => setUsername(e.target.value)} />
			<input type='password' placeholder='password' onChange={(e) => setPassword(e.target.value)} />
			<button type='submit'>{loginButton}</button>
		</form>
	);

	return (
		<div className='login-page text-center'>
			<img  src={logo} alt="Logo" style={{ maxWidth: '300px' }}></img>
			<br />
			<div className='form'>
				<h2>Login</h2>
				<br />
				{ loginForm }
			</div>
		</div>
	);
};

export default Login;
