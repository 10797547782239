import React from "react";
import { Card } from 'antd';

const ExtraMessageCard = () => {
  return (
    <div className='col-sm-12'>
        <Card className='global-item-shadow' style={{ textAlign: 'center' }}>
            We are pleased to have you working with us! If you have any questions about your
            jobs, please contact your <i>Manager</i>.
        </Card>
    </div>
  );
}

export default ExtraMessageCard;
