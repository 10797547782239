import React from "react";
import SearchBar from './searchBar'
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'


const Header = ({ title, searchVisible, onChange, buttonOne, buttonOneIcon, buttonOneOnClick }) => {
	const cookies = new Cookies();
    const logout = () => {
        cookies.remove('shovelled-token');
        window.location.reload();
    }
    return (
        <div className='page-header'>
            <h1>{ title }</h1>
            <a className='header-btn' onClick={logout}><FontAwesomeIcon icon={faDoorOpen}/></a>
            { buttonOne ? <a className='header-btn' onClick={buttonOneOnClick}><FontAwesomeIcon icon={buttonOneIcon}/></a> : <></> }
            { searchVisible ? <SearchBar onChange={onChange} /> : <></>}
        </div>
    );
}

export default Header;
