import React from "react";
import { Card, Collapse } from 'antd';
import ConfirmedJob from './confirmedJob';

const { Panel } = Collapse;

const ConfirmedCard = ({ jobs, getData, setLoading, accountInfo }) => {
    let panelData = [];
    let payoutTotal = 0;
    jobs.forEach(item => {
		payoutTotal += item.payout;
        panelData.push(
            <Panel 
                header={item.streetName} 
                style={{ textAlign: 'center' }} 
                key={item.id}
            >
                <ConfirmedJob
                    jobInfo={item}
                    setLoading={setLoading}
                    getData={getData}
                    accountInfo={accountInfo}
                />
            </Panel>
        );
    });

    let count = 0;
    return (
        <div className='col-sm-6' style={{ marginLeft: 'auto', marginRight: 'auto' }} key={count++}>
            <Card className='global-item-shadow' style={{ marginTop: 10, marginBottom: 10 }}>
                <h3 className='text-center'>Confirmed Jobs</h3>
                <br />
                <p className='text-center'>
                    You have confirmed the following addresses and will be expected to complete them.
                </p>
                <br />
                <Collapse accordion>{panelData}</Collapse>
                <br />
                <div className='text-center' style={{ marginTop: 20 }}>
					<h6>Payout for Confirmed Properties:</h6>
                    <p>{'$' + payoutTotal}</p>
                </div>
            </Card>
        </div>
    );
}

export default ConfirmedCard;
