import React, { useState } from 'react';
import { Button, Drawer, message, Input } from 'antd';
import Cookies from 'universal-cookie';

const BillingDrawer = ({ data, setLoading }) => {
	const addUsersURL = window.APIURL + '/customers/add-customer';
    const { TextArea } = Input;

	const [visible, setVisible] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [streetName, setStreetName] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [city, setCity] = useState('');
	const [rate, setRate] = useState('');
	const [description, setDescription] = useState('');
	const [notes, setNotes] = useState('');
	const [payout, setPayout] = useState('');
    
	const openDrawer = () => {
		setVisible(true);
	};

	const onUpdate = () => {
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				email: email,
				streetName: streetName,
				postalCode: postalCode,
				city: city,
				rate: rate,
				description, description,
				notes: notes,
				payout: payout
			}),
		};
		fetch(addUsersURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (!data.message.includes('error')) {
					message.success('Customer successfully added.');
				} else {
					message.error('An error occured while adding customer.');
				}
			});

		setVisible(false);
		setLoading(true);
	};
	
	const onClose = () => {
		setFirstName('');
		setLastName('');
		setPhone('');
		setEmail('');
		setStreetName('');
		setPostalCode('');
		setCity('');
		setRate('');
		setDescription('');
		setNotes('');
		setPayout('');
		setVisible(false);
	};

    return (
        <React.Fragment>
            <Button
                onClick={openDrawer}
                shape='round'
                type='primary'
                style={{ position: 'fixed', right: '12px', bottom: '12px', zIndex: 100 }}>
                Add New Customer
            </Button>
            <Drawer
                title={'Add New Customer'}
                placement='right'
                width={300}
                closable={false}
                onClose={onClose}
                visible={visible}
                footer={
                    <div
                        style={{
                            textAlign: 'center',
                        }}>
                        <Button onClick={onUpdate} shape='round' type='primary' style={{ marginRight: 8 }}>
                            Add New Customer
                        </Button>
                        <Button onClick={onClose} shape='round'>
                            Cancel
                        </Button>
                    </div>
                }>
                <div className='cust-info'>
                    <form>
                        <label>Contact Info</label>
                        <Input
                            id='firstName'
                            name='firstName'
                            placeholder={firstName || 'First Name / Company Name'}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <Input
                            id='lastName'
                            name='lastName'
                            placeholder={lastName || 'Last Name'}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <Input
                            id='phone'
                            name='phone'
                            type='tel'
                            placeholder={phone || 'Phone Number'}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                        <Input
                            id='email'
                            name='email'
                            type='email'
                            placeholder={email || 'Email'}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Address
                        </label>
                        <Input
                            id='address'
                            name='address'
                            placeholder={streetName || 'Address'}
                            onChange={(e) => setStreetName(e.target.value)}
                            required
                        />
                        <Input
                            id='postal'
                            name='postal'
                            placeholder={postalCode || 'Postal Code (X#X #X#)'}
                            onChange={(e) => setPostalCode(e.target.value)}
                            required
                        />
                        <Input
                            id='city'
                            name='city'
                            placeholder={city || 'City'}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Rate ($)
                        </label>
                        <Input
                            id='rate'
                            name='rate'
                            type='number'
                            min='0'
                            max='2500'
                            placeholder={'$' + (rate || '')}
                            onChange={(e) => setRate(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Payout ($)
                        </label>
                        <Input
                            id='payout'
                            name='payout'
                            type='number'
                            min='0'
                            max='2500'
                            placeholder={'$' + (payout || '')}
                            onChange={(e) => setPayout(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Property Description
                        </label>
                        <TextArea
                            id='description'
                            name='description'
                            placeholder={description || 'Property Description'}
                            onChange={(e) => setDescription(e.target.value)}
                            autoSize 
                            required
                        />
                        <label>
                            <br />
                            Property Notes
                        </label>
                        <TextArea 
                            id='notes' 
                            name='notes'
                            placeholder={notes || 'Property Notes'} 
                            onChange={(e) => setNotes(e.target.value)}
                            autoSize 
                            required 
                        />
                    </form>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

export default BillingDrawer;