import React, { useState } from 'react';
import { Drawer, Button, Popconfirm, Input, message } from 'antd';
import Cookies from 'universal-cookie';

import 'antd/dist/antd.css';
import '../../../css/CustomerInfo.css';

const UserCard = ({ data }) => {
	const updateURL = window.APIURL + '/users/update-user';
	const removeUserURL = window.APIURL + '/users/remove-user';
	
	const [visible, setVisible] = useState(false);
	const [firstName, setFirstName] = useState(data.firstName);
	const [lastName, setLastName] = useState(data.lastName);
	const [phone, setPhone] = useState(data.phone);
	const [email, setEmail] = useState(data.email);
	const [streetName, setStreetName] = useState(data.streetName);
	const [postalCode, setPostalCode] = useState(data.postalCode);
	const [city, setCity] = useState(data.city);
	const [rate, setRate] = useState(data.rate);
	const [position, setPosition] = useState(data.position);

	const openDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setFirstName(data.firstName);
		setLastName(data.lastName);
		setPhone(data.phone);
		setEmail(data.email);
		setStreetName(data.streetName);
		setPostalCode(data.postalCode);
		setCity(data.city);
		setRate(data.rate);
		setPosition(data.position);
		setVisible(false);
	};

	const onUpdate = () => {
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				id: data.id,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				email: email,
				streetName: streetName,
				postalCode: postalCode,
				city: city,
				rate: rate,
				position: position,
			}),
		};
		fetch(updateURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (!data.message.includes('error')) {
					message.success('User successfully updated.');
				} else {
					message.error('An error occured while updating user.');
				}
			});
		setVisible(false);
	};

	const confirmDelete = () => {
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				id: data.id,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				email: email,
				streetName: streetName,
				postalCode: postalCode,
				city: city,
				rate: rate,
				position: position,
			}),
		};
		fetch(removeUserURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (data.hasOwnProperty('message') && data.message.includes('Successfully deleted account')) {
					message.success('User successfully deleted.');
				} else if (data.hasOwnProperty('error') && data.error.includes('User does not exist')) {
					message.error('Cannot delete user as they do not exist.');
				} else {
					message.error('An error occured while deleting user.');
				}
			});
		setVisible(false);
	};

	return (
		<React.Fragment>
			<Button
				onClick={openDrawer}
				shape='round'
				type='primary'>
				Edit
			</Button>
			<Drawer
				title={'Edit Details: ' + (firstName || '') + ' ' + (lastName || 'Unknown User')}
				placement='right'
				width={300}
				closable={false}
				onClose={onClose}
				visible={visible}
				footer={
					<div
						style={{
							textAlign: 'center',
						}}>
						<Button onClick={onUpdate} shape='round' type='primary' style={{ marginRight: 8 }}>
							Update
						</Button>
						<Popconfirm
							title='Are you sure to delete this user?'
							onConfirm={confirmDelete}
							okText='Yes'
							cancelText='No'>
							<Button shape='round' style={{ marginRight: 8 }} type='danger'>
								Delete
							</Button>
						</Popconfirm>
						<Button onClick={onClose} shape='round'>
							Cancel
						</Button>
					</div>
				}>
				<div className='cust-info'>
					<form>
						<label>Contact Info</label>
						<Input
							id='firstName'
							name='firstName'
							value={firstName || ''}
							placeholder={'First Name / Company Name'}
							onChange={(e) => setFirstName(e.target.value)}
							required
						/>
						<Input
							id='lastName'
							name='lastName'
							value={lastName || ''}
							placeholder={'Last Name'}
							onChange={(e) => setLastName(e.target.value)}
							required
						/>
						<Input
							id='phone'
							name='phone'
							type='tel'
							value={phone || ''}
							placeholder={'Phone Number'}
							onChange={(e) => setPhone(e.target.value)}
							required
						/>
						<Input
							id='email'
							name='email'
							type='email'
							value={email || ''}
							placeholder={'Email'}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
						<label>
							<br />
							Address
						</label>
						<Input
							id='address'
							name='address'
							value={streetName || ''}
							placeholder={'Address'}
							onChange={(e) => setStreetName(e.target.value)}
							required
						/>
						<Input
							id='postal'
							name='postal'
							value={postalCode || ''}
							placeholder={'Postal Code (X#X #X#)'}
							onChange={(e) => setPostalCode(e.target.value)}
							required
						/>
						<Input
							id='city'
							name='city'
							value={city || ''}
							placeholder={'City'}
							onChange={(e) => setCity(e.target.value)}
							required
						/>
						<label>
							<br />
							Job Details
						</label>
						<select
							name='roles'
							id='roles'
							defaultValue={position || 'Unassigned'}
							onChange={(e) => setPosition(e.target.value)}
							required>
							<option value='1'>Contractor</option>
							<option value='2'>Manager</option>
							<option value='3'>Admin</option>
							<option value='Unassigned' disabled hidden>
								Unassigned
							</option>
						</select>
						<Input
							id='rate'
							name='rate'
							type='number'
							min='0'
							max='2500'
							value={rate || ''}
							placeholder={'Rate'}
							onChange={(e) => setRate(e.target.value)}
							required
						/>
					</form>
				</div>
			</Drawer>
		</React.Fragment>
	);
};

export default UserCard;
