import React from "react";
import { Progress } from 'antd';

const GlobalProgress = ({ data }) => {
    return (
        <div className="row">
            <div className="col-sm-3" > 
                <h3>Unassigned</h3>
                <Progress
                        type="dashboard"
                        format={() => data[0] + ' / ' + data[4]}
                        gapDegree={75}
                        style={{
                            paddingBottom: "20px"
                        }}
                        strokeColor='#ef7777'
                        percent={Math.round((data[0]/data[4])*100)}
                />
            </div>
            <div className="col-sm-3" >
                <h3>Unconfirmed</h3>
                <Progress
                        type="dashboard"
                        format={() => data[1] + ' / ' + data[4]}
                        gapDegree={75}
                        style={{
                            paddingBottom: "20px"
                        }}
                        strokeColor={'#efe077'}
                        percent={Math.round((data[1]/data[4])*100)}
                />
            </div>
            <div className="col-sm-3" >
                <h3>Confirmed</h3>
                <Progress
                        type="dashboard"
                        format={() => data[2] + ' / ' + data[4]}
                        gapDegree={75}
                        style={{
                            paddingBottom: "20px"
                        }}
                        strokeColor={'#5588ff'}
                        percent={Math.round((data[2]/data[4])*100)}
                />
            </div>
            <div className="col-sm-3" >
                <h3>Completed</h3>
                <Progress
                        type="dashboard"
                        format={() => data[3] + ' / ' + data[4]}
                        gapDegree={75}
                        style={{
                            paddingBottom: "20px"
                        }}
                        strokeColor={'#87d068'}
                        percent={Math.round((data[3]/data[4])*100)}
                        className="red-text"
                />
            </div>
        </div>
  );
}

export default GlobalProgress;
