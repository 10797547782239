import React from "react";
import { Input } from "antd";

import { SearchOutlined } from '@ant-design/icons';

const SearchBar = ({ onChange }) => {
  return (
    <div className='searchBar'>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search"
        onChange={onChange}
        style={{width: '30vw'}}
        allowClear
      />
    </div>
  );
}

export default SearchBar;
