import React from 'react';
import { useLocation } from 'react-router-dom'
import { Tooltip } from 'antd';
// Import React Router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import '../../css/Nav.css';

const NavMin = ({ accountInfo, open, setOpen }) => {
	const location = useLocation();
	const contractorMenuItems = (
		<React.Fragment>
			<li>
				<Link to='/jobs'>
					<Tooltip 
						placement='right' 
						overlayStyle={{ zIndex: 9999 }} 
						color={'#3a3f48'} 
						title='My Jobs'>
						<i className={((location.pathname === '/jobs') ? 'selected-menu-item ' : '') + 'fa fa-toolbox'}></i>
					</Tooltip>
				</Link>
			</li>
			<li>
				<Link to='/resources'>
					<Tooltip 
						placement='right' 
						overlayStyle={{ zIndex: 9999 }} 
						color={'#3a3f48'} 
						title='Resources'>
						<i className={((location.pathname === '/resources') ? 'selected-menu-item ' : '') + 'fa fa-book-open'}></i>
					</Tooltip>
				</Link>
			</li>
		</React.Fragment>
	);

	const managerMenuItems = (
		<React.Fragment>
			{ contractorMenuItems }
			<div className='menu-divider' />
			<li>
				<Link to='/map'>
					<Tooltip
						placement='right'
						overlayStyle={{ zIndex: 9999 }}
						color={'#3a3f48'}
						title='Map'>
						<i className={((location.pathname === '/map') ? 'selected-menu-item ' : '') + 'fa fa-map-marked-alt'}></i>
					</Tooltip>
				</Link>
			</li>
			<li>
				<Link to='/analytics'>
					<Tooltip
						placement='right'
						overlayStyle={{ zIndex: 9999 }}
						color={'#3a3f48'}
						title='Analytics'>
						<i className={((location.pathname === '/analytics') ? 'selected-menu-item ' : '') + 'fa fa-chart-line'}></i>
					</Tooltip>
				</Link>
			</li>
			<li>
				<Link to='/gallery'>
					<Tooltip
						placement='right'
						overlayStyle={{ zIndex: 9999 }}
						color={'#3a3f48'}
						title='Gallery'>
						<i className={((location.pathname === '/gallery') ? 'selected-menu-item ' : '') + 'fa fa-images'}></i>
					</Tooltip>
				</Link>
			</li>
			<li>
				<Link to='/reset'>
					<Tooltip placement='right' 
						overlayStyle={{ zIndex: 9999 }} 
						color={'#3a3f48'} 
						title='Reset Data'>
						<i className={((location.pathname === '/reset') ? 'selected-menu-item ' : '') + 'fa fa-undo'}></i>
					</Tooltip>
				</Link>
			</li>
		</React.Fragment>
	);

	const adminMenuItems = (
		<React.Fragment>
			{ managerMenuItems }
			<div className='menu-divider' />
			<li>
				<Link to='/billing'>
					<Tooltip
						placement='right'
						overlayStyle={{ zIndex: 9999 }}
						color={'#3a3f48'}
						title='Customer Billing Information'>
						<i className={((location.pathname === '/billing') ? 'selected-menu-item ' : '') + 'fa fa-file-invoice-dollar'}></i>
					</Tooltip>
				</Link>
			</li>
			<li>
				<Link to='/users'>
					<Tooltip 
						placement='right' 
						overlayStyle={{ zIndex: 9999 }} 
						color={'#3a3f48'} 
						title='Platform Users'>
						<i className={((location.pathname === '/users') ? 'selected-menu-item ' : '') + 'fa fa-users'}></i>
					</Tooltip>
				</Link>
			</li>
		</React.Fragment>
	);

	const sidebarBrand = (
		<div className='sidebar-brand'>
			<div id='close-sidebar' onClick={() => setOpen(!open)}>
				<i className='fas fa-arrow-circle-right' style={{ paddingLeft: '5px' }}></i>
			</div>
		</div>
	);

	const sidebarMenu = (
		<div className='sidebar-menu'>
			<ul>
				{ accountInfo[2] > 1 ? (accountInfo[2] > 2 ? adminMenuItems : managerMenuItems) : contractorMenuItems }
			</ul>
		</div>
	);

	return (
		<nav id='show-sidebar' className='sidebar-wrapper small'>
			<div className='sidebar-content'>
				{ sidebarBrand }
				{ sidebarMenu }
			</div>
			<div className='sidebar-footer' />
		</nav>
	);
};

export default NavMin;
