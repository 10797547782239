import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

import Header from '../global/header';
import Loading from '../global/loading';
import PendingCard from './jobs/pendingCard';
import ConfirmedCard from './jobs/confirmedCard';
import UnassignedCard from './jobs/unassignedCard';
import ExtraMessageCard from './jobs/extraMessageCard';

import 'antd/dist/antd.css';

let confirmedJobs = [];
let pendingJobs = [];

const Jobs = ({ accountInfo }) => {
	const [loading, setLoading] = useState(true);

	const getData = async () => {
		const jobURL = window.APIURL + '/jobs/get-all-assigned';
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
		};
		const jobInfo = await fetch(jobURL + '?id=' + accountInfo[3], requestOptions);
		const jobJSON = await jobInfo.json();

		if (jobJSON.hasOwnProperty('error')) {
			return;
		}

		confirmedJobs = [];
		pendingJobs = [];

		await jobJSON.forEach((value) => {
			if (!value.confirmed) pendingJobs.push(value);
			else if (value.confirmed && !value.completed) confirmedJobs.push(value);
		});
	}

	useEffect(async () => {
		await getData();
		setLoading(false);
	}, []);

	let display = [];
	if (pendingJobs.length > 0)
		display.push(<PendingCard jobs={pendingJobs} setLoading={setLoading} getData={getData} key={1} />);

	if (confirmedJobs.length > 0) 
		display.push(<ConfirmedCard jobs={confirmedJobs} getData={getData} setLoading={setLoading} accountInfo={accountInfo} key={2} />);

	if (display.length === 0) 
		display.push(<UnassignedCard key={3}/>);

	
	const loadingIcon = <Loading />;
	const pageContent = (
		<div className='container'>
			<div className='row'>{display}</div>
			<div className='row'>
				<ExtraMessageCard />
			</div>
		</div>
	);

	return (
		<div className='page-content'>
			<Header title='My Jobs' icon='fa fa-toolbox' searchVisible={false} />
			<div className='pad'>
				{(loading) ? loadingIcon : pageContent}
			</div>
		</div>
	);
};

export default Jobs;
