import React from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
	const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    return (
        <div className='loading'>
            <Spin indicator={loadingIcon} />
        </div>
    );
}

export default Loading;