import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Image, Skeleton } from 'antd';

const GalleryImage = ({ imgURL }) => {
	const imageURL = window.APIURL + '/' + imgURL;
	const [loading, setLoading] = useState(true);
	const [imageData, setImageData] = useState();

	useEffect(() => {
        const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			}
		};
		fetch(imageURL, requestOptions)
			.then((response) => response.blob())
			.then((data) => {
				const imageObjectURL = URL.createObjectURL(data);
				setImageData(imageObjectURL);
				setLoading(false);
			});
    }, [])

	const imageLoading = (
		<Skeleton.Image />
	)

	const imageContent = (
		<Image width={100} src={imageData} />
	)

	return (
		<div className="gallery-item-pad">
			{ loading ? imageLoading : imageContent }
		</div>
	);
};

export default GalleryImage;
