import React from "react";
import { Table } from 'antd';

const IndividualTable = ({ data }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            fixed: 'left',
            width: 125,
            align: 'center',
            key: 1,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width: 60,
            align: 'center',
            key: 2,
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: 'Unconfirmed',
            dataIndex: 'unconfirmed',
            width: 110,
            align: 'center',
            key: 3,
            sorter: (a, b) => a.unconfirmed - b.unconfirmed,
        },
        {
            title: 'Confirmed',
            dataIndex: 'confirmed',
            width: 100,
            align: 'center',
            key: 4,
            sorter: (a, b) => a.confirmed - b.confirmed,
        },
        {
            title: 'Completed',
            dataIndex: 'completed',
            width: 100,
            align: 'center',
            key: 5,
            sorter: (a, b) => a.completed - b.completed,
        },
        {
            title: 'Payout ($)',
            dataIndex: 'payout',
            width: 100,
            align: 'center',
            key: 6,
            sorter: (a, b) => a.payout - b.payout,
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {}

    return (
        <Table 
            columns={columns} 
            dataSource={data} 
            onChange={onChange} 
            scroll={{ x: 250 }}
            size="small" 
        />
  );
}

export default IndividualTable;
