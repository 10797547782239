import React from 'react';
import { Descriptions, Card, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';

import Header from '../global/header';

const Jobs = ({ accountInfo }) => {
    return (
        <div className='page-content'>
            <Header title="Resources" searchVisible={false}/>
            <div className='pad'>
            <Card className='global-item-shadow'>
                <Descriptions title="Contractor Manual & Agreement (2022/2023)" column={1} extra={
                    <Button type="default" href={'/downloads/Manual-22-23.pdf'} icon={<DownloadOutlined />}>
                        Download
                    </Button>
                }>
                    <Descriptions.Item>
                        This document outlines the process, expectations, and other general information that contractors must be aware of.
                    </Descriptions.Item>
                    <Descriptions.Item label={"Last Updated"}>
                        November 11, 2022
                    </Descriptions.Item>
                </Descriptions>
            </Card>
            </div>
            
        </div>
    );
};

export default Jobs;
