import React, { useState } from 'react';
import { Popconfirm, Button, message, Card } from 'antd';
import Cookies from 'universal-cookie';

import Header from '../global/header';
import Loading from '../global/loading';

import 'antd/dist/antd.css';

const ResetData = () => {
	const resetURL = window.APIURL + '/jobs/reset-assigned-data';
	const deleteURL = window.APIURL + '/images/delete-images';
	const [loading, setLoading] = useState(false);

	const resetAssigned = () => {
		setLoading(true);
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				resetData: true,
			}),
		};
		fetch(resetURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (typeof data.message != 'undefined' && data.message.includes('Success')) {
					setLoading(false);
					message.success('User data successfully reset.');
				} else {
					message.error('An error occured while resetting data.');
				}
			});
	};

	const deletePhotos = () => {
		setLoading(true);
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				deletePhotos: true,
			}),
		};
		fetch(deleteURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (typeof data.message != 'undefined' && data.message.includes('Success')) {
					setLoading(false);
					message.success('Photos successfully deleted.');
				} else {
					message.error('An error occured while deleting photos.');
				}
			});
	};

	const loadingIcon = <Loading />;
	const pageContent = (
		<>
			<Card className='global-item-shadow text-center'>
				<h3>Reset Data</h3>
				<br/>
				<p>
					<b>WARNING:</b> By hitting this reset button, you will be removing all assigned data to
					each client. This should only be done when it is time to begin assigning new routes to
					the contractors.
				</p>
				<Popconfirm
					title='Are you sure to reset all the assigned user data?'
					onConfirm={resetAssigned}
					okText='Yes'
					cancelText='No'>
					<Button shape='round' type='danger'>
						Reset Data
					</Button>
				</Popconfirm>
			</Card>
			<Card className='global-item-shadow text-center'>
				<h3>Delete Photos</h3>
				<br/>
				<p>
					<b>WARNING:</b> By hitting this reset button, you will be deleting all photos uploaded
					to the server. This should only be done when it is time to begin assigning new routes to
					the contractors.
				</p>
				<Popconfirm
					title='Are you sure to delete all the photos?'
					onConfirm={deletePhotos}
					okText='Yes'
					cancelText='No'>
					<Button shape='round' type='danger'>
						Delete Photos
					</Button>
				</Popconfirm>
			</Card>
		</>
	);

	return (
		<div className='page-content'>
			<Header title="Reset Data" searchVisible={false}/>
			<div className='pad'>
				{ loading ? loadingIcon : pageContent }
			</div>
		</div>
	);
};

export default ResetData;
