import React, { useState } from 'react';
import { Button, Drawer, message, Input } from 'antd';
import Cookies from 'universal-cookie';

const UserDrawer = ({ setLoadingData }) => {
	const addUsersURL = window.APIURL + '/users/add-user';

	const [visible, setVisible] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [streetName, setStreetName] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [city, setCity] = useState('');
	const [rate, setRate] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [position, setPosition] = useState('');
    
	const openDrawer = () => {
		setVisible(true);
	};

	const onUpdate = () => {
        setLoadingData(true);
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify({
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				email: email,
				streetName: streetName,
				postalCode: postalCode,
				city: city,
				rate: rate,
				position: position,
				username: username,
				password: password,
			}),
		};
		fetch(addUsersURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (data.hasOwnProperty('message') && data.message.includes('Success')) {
					message.success('User successfully added.');
				} else if (data.hasOwnProperty('error') && data.error.includes('Username already')) {
					message.error('Username already exists.');
				} else {
					message.error('An error occured while adding user.');
				}
			});
		setVisible(false);
		setLoadingData(false);       
	};

	const onClose = () => {
		setFirstName('');
		setLastName('');
		setPhone('');
		setEmail('');
		setStreetName('');
		setPostalCode('');
		setCity('');
		setRate('');
		setPosition('');
		setVisible(false);
	};

    return (
        <React.Fragment>
            <Button
                onClick={openDrawer}
                shape='round'
                type='primary'
                style={{ position: 'fixed', right: '12px', bottom: '12px', zIndex: 100 }}>
                Add New Customer
            </Button>
            <Drawer
                title={'Add New Customer'}
                placement='right'
                width={300}
                closable={false}
                onClose={onClose}
                visible={visible}
                footer={
                    <div
                        style={{
                            textAlign: 'center',
                        }}>
                        <Button onClick={onUpdate} shape='round' type='primary' style={{ marginRight: 8 }}>
                            Add New Customer
                        </Button>
                        <Button onClick={onClose} shape='round'>
                            Cancel
                        </Button>
                    </div>
                }>
                <div className='cust-info'>
                    <form>
                        <label>Account Info</label>
                        <Input
                            id='username'
                            name='username'
                            placeholder={username || 'Username'}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <Input
                            id='password'
                            name='password'
                            placeholder={password || 'Password'}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Contact Info
                        </label>
                        <Input
                            id='firstName'
                            name='firstName'
                            placeholder={firstName || 'First Name / Company Name'}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <Input
                            id='lastName'
                            name='lastName'
                            placeholder={lastName || 'Last Name'}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <Input
                            id='phone'
                            name='phone'
                            type='tel'
                            placeholder={phone || 'Phone Number'}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                        <Input
                            id='email'
                            name='email'
                            type='email'
                            placeholder={email || 'Email'}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Address
                        </label>
                        <Input
                            id='address'
                            name='address'
                            placeholder={streetName || 'Address'}
                            onChange={(e) => setStreetName(e.target.value)}
                            required
                        />
                        <Input
                            id='postal'
                            name='postal'
                            placeholder={postalCode || 'Postal Code (X#X #X#)'}
                            onChange={(e) => setPostalCode(e.target.value)}
                            required
                        />
                        <Input
                            id='city'
                            name='city'
                            placeholder={city || 'City'}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                        <label>
                            <br />
                            Job Details
                        </label>
                        <select
							name='roles'
							id='roles'
							defaultValue={position || 'Unassigned'}
							onChange={(e) => setPosition(e.target.value)}
							required>
							<option value='1'>Contractor</option>
							<option value='2'>Manager</option>
							<option value='3'>Admin</option>
							<option value='Unassigned' disabled hidden>
								Unassigned
							</option>
						</select>
                        <Input
                            id='rate'
                            name='rate'
                            type='number'
                            min='0'
                            max='2500'
                            placeholder={'$' + (rate || '')}
                            onChange={(e) => setRate(e.target.value)}
                            required
                        />
                    </form>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

export default UserDrawer;