import React from "react";
import { Card, message, Button, Collapse } from 'antd';
import Cookies from 'universal-cookie';
const { Panel } = Collapse;

const PendingCard = ({ jobs, setLoading, getData }) => {
    let panelData = [];
	let payoutTotal = 0;

    jobs.forEach(item => {
		payoutTotal += item.payout;
        panelData.push(
            <Panel 
                header={item.streetName} 
                style={{ textAlign: 'center' }} 
                key={item.id}
            >
                <h6>Address:</h6>
                <p>{item.streetName}</p>
                <h6>Description:</h6>
                <p>{item.description}</p>
                <h6>Notes:</h6>
                <p>{item.notes}</p>
                <h6>Property Payout:</h6>
                <p>{"$" + item.payout}</p>
            </Panel>
        );
    });

    const acceptAll = async () => {
		setLoading(true);

		const confirmURL = window.APIURL + '/jobs/confirm-assigned';
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify(jobs),
		};
		const updateInfo = await fetch(confirmURL, requestOptions);
		const updateJSON = await updateInfo.json();

		if (updateJSON.hasOwnProperty('message') && updateJSON.message.includes('Success')) {
			await getData();
			message.success('All jobs accepted.');
		} else message.error('An error occured while confirming jobs. Please report this to an administrator.');
		setLoading(false);
	};

	const declineAll = async () => {
		setLoading(true);

		const declineURL = window.APIURL + '/jobs/decline-assigned';
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
			body: JSON.stringify(jobs),
		};
		const updateInfo = await fetch(declineURL, requestOptions);
		const updateJSON = await updateInfo.json();

		if (updateJSON.hasOwnProperty('message') && updateJSON.message.includes('Success')) {
			await getData();
			message.success('All jobs declined.');
		} else message.error('An error occured while declining jobs. Please report this to an administrator.');

		setLoading(false);
	};

    return (
        <div className='col-sm-6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Card className='global-item-shadow' style={{ marginTop: 10, marginBottom: 10 }}>
                <h3 className='text-center'>Assigned Jobs</h3>
                <br />
                <p className='text-center'>
                    You must confirm the addresses below as soon as possible, or they may be reassigned.
                </p>
                <br />
                <Collapse accordion>{panelData}</Collapse>
                <div className='text-center' style={{ marginTop: 20 }}>
					<h6>Payout for Assigned Properties:</h6>
					<p>{'$' + payoutTotal}</p>
                    <Button onClick={acceptAll} shape='round' type='primary' style={{ margin: '7px' }}>
                        Confirm All
                    </Button>
                    <Button onClick={declineAll} shape='round' type='danger' style={{ margin: '7px' }}>
                        Decline All
                    </Button>
                </div>
            </Card>
            <br />
        </div>
    );
}

export default PendingCard;
