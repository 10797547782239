import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Cookies from 'universal-cookie';

import Header from '../global/header';
import Loading from '../global/loading';
import HouseMarker from '../manager/map/houseMarker';
import PersonMarker from '../manager/map/personMarker';


import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'

import 'antd/dist/antd.css';
import '../../css/Map.css';

const city = [49.8951, -97.1384]; // Winnipeg = 49.8951, -97.1384

let data = [];
let matches = [];
let clientList = [];
let contractorID = [];
let contractorName = [];
let contractorList = [];
let userJSON = [{ value: 'Unassigned' }];
let keyCount = 0;

const MapView = () => {
	const getClients = window.APIURL + '/customers/get-all-customers-map';
	const getContractors = window.APIURL + '/users/get-all-users-map';

	const [updatedData, setUpdatedData] = useState(0);
	const [loading, setLoading] = useState(true);
	const [multiSelect, setMultiSelect] = useState(false);
	const [selected, setSelected] = useState(false);

	const sortData = async () => {
		data = [];
		matches.forEach(element => {
			if (typeof element.description != 'undefined')
				data.push(
					<HouseMarker
						key={keyCount++} 
						data={element} 
						ids={contractorID} 
						names={contractorName} 
						users={userJSON}
						multiSelect={multiSelect} 
						setSelected={setSelected}
					/>
				);
			else 
				data.push(
					<PersonMarker 
						key={keyCount++} 
						data={element}  
						multiSelect={multiSelect} 
						setSelected={setSelected}
					/>
				);
		});
	};

	useEffect(async () => {
		matches = [];
		clientList = [];
		contractorID = [];
		contractorName = [];
		contractorList = [];
		userJSON = [{ value: 'Unassigned' }];
		data = [];
		keyCount = 0;
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');

		const requestOptions = {
			mode: 'cors',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
		};

		const contractorInfo = await fetch(getContractors, requestOptions);
		const clientInfo = await fetch(getClients, requestOptions);
		const contractorJSON = await contractorInfo.json();
		const clientJSON = await clientInfo.json();
		let userCount = 1;
		contractorID.push(0);
		contractorName.push("Unassigned");
		await contractorJSON.forEach((value) => {
			contractorList.push(value);
			contractorID.push(value.id);
			contractorName.push(value.firstName + ' ' + value.lastName);
			matches.push(value);
			userJSON[userCount++] = { value: value.firstName + ' ' + value.lastName };
		});

		await clientJSON.forEach((value) => {
			clientList.push(value);
			matches.push(value);
		});

		await sortData();
		setLoading(false);
	}, []);

	const searchData = (value) => {
		console.log(value)
		matches = [];
		clientList.forEach((input) => {
			let name = input.firstName + ' ' + input.lastName;
			if (name.toLowerCase().search(value) !== -1 || input.streetName.toLowerCase().search(value) !== -1) 
				matches.push(input);
			else if (input.assignedUser !== 0 && contractorName[contractorID.indexOf(input.assignedUser)].toLowerCase().search(value) !== -1) 
				matches.push(input);
		});

		contractorList.forEach((input) => {
			let name = input.firstName + ' ' + input.lastName;
			if (name.toLowerCase().search(value) !== -1) matches.push(input);
		});

		sortData();
		setUpdatedData(updatedData + 1);
	};

	const multiSelectClicked = () => {
		setMultiSelect(!multiSelect);
		sortData();
		// console.log(multiSelect)
	}

	const loadingIcon = (
		<div className='pad'>
			<Loading />
		</div>
	);

	const pageContent = (
		<MapContainer 
			center={city} 
			zoom={10} 
			scrollWheelZoom={true}
			style={{height: '88vh'}}
		>
			<TileLayer
				url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
				//url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{data}
		</MapContainer>
	);

	return (
		<div className='page-content'>
			<Header 
				title='Map' 
				searchVisible={true} 
				onChange={(e) => searchData(e.target.value.toLowerCase().replace(/[^a-zA-Z0-9_\s]/g, ''))} 
				buttonOne={true} 
				buttonOneIcon={faCheckSquare} 
				buttonOneOnClick={multiSelectClicked}
			/>
			
			<div className='pad'>
				{ loading ? loadingIcon : pageContent }
			</div>
		</div>
	);
};
export default MapView;
