import React from "react";
import { Card } from 'antd';

const UnassignedCard = () => {
  return (
    <div className='col-sm-12'>
        <Card className='global-item-shadow' style={{ textAlign: 'center' }}>
            It appears that there is nothing assigned to you at this moment.
        </Card>
    </div>
  );
}

export default UnassignedCard;
