import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'universal-cookie';

import Loading from '../global/loading';
import Header from '../global/header';

import FullscreenTable from './billing/fullscreenTable';
import MobileList from './billing/mobileList';
import BillingDrawer from './billing/billingDrawer';

import 'antd/dist/antd.css';

const BillingInfo = () => {
	const usersURL = window.APIURL + '/customers/get-all-customers';

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [updatedData, setUpdatedData] = useState(0);
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 825px)' }); // Or maybe 1224px or 825px

	useEffect(async () => {
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');
		const requestOptions = {
			mode: 'cors',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + cookieToken,
			},
		};
		fetch(usersURL, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					let temp = [];
					result.forEach(element => {
						temp.push(element);
					});
					setData(temp);
					setSearchData(temp);
					setLoading(false);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					setLoading('error');
				},
			);
	}, []);

	const search = (value) => {
		let searchData = [];
		data.forEach((input) => {
			if (
				input.streetName.search(value) !== -1 ||
				input.firstName.search(value) !== -1 ||
				input.lastName.search(value) !== -1 ||
				input.email.search(value) !== -1
			)
				searchData.push(input);
		});
		setSearchData(searchData);
		setUpdatedData(updatedData + 1);
	};

	const loadingIcon = (
		<div className='pad'>
			<Loading />
		</div>
	);
	const pageContent = (
		<React.Fragment>
			<div className='pad'>
				{isTabletOrMobile ? <MobileList data={searchData} /> : <FullscreenTable data={searchData} />}
			</div>
			<BillingDrawer data={data} setLoading={setLoading} />
		</React.Fragment>
	);

	return (
		<div className='page-content'>
			<Header title='Customers' searchVisible={true} onChange={(e) => search(e.target.value)}/>
			{ loading ? loadingIcon : pageContent }
		</div>
	);
};

export default BillingInfo;
