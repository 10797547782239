// Import React
import React, { useState, useEffect } from 'react';
// Import React Router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';

// Import Components
import AdminNav from './components/global/nav';
import NavMin from './components/global/navMin';
import Login from './components/global/login';
import Loading from './components/global/loading';

import MapView from './components/manager/map';
import Resources from './components/contractor/resources';
import BillingInfo from './components/admin/billingInfo';
import Users from './components/admin/users';
import Jobs from './components/contractor/jobs';
import Profile from './components/contractor/profile';
import ResetData from './components/manager/reset';
import Gallery from './components/manager/gallery';
import Analytics from './components/manager/analytics';

// Import CSS
import './css/App.css';

// Display Application
function App() {
	const tokenURL = window.APIURL + '/accounts/verify-token';
	const [open, setOpen] = useState(window.innerWidth < 768 ? false : true); //
	const [loggedIn, setLoggedIn] = useState([false, '', 0, 0]);
	const [loading, setLoading] = useState(true);

	useEffect(async () => {
		const cookies = new Cookies();
		let cookieToken = cookies.get('shovelled-token');

		if (typeof cookieToken !== 'undefined' && cookieToken !== '') {
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + cookieToken,
				},
			};

			const tokenInfo = await fetch(tokenURL, requestOptions);
			const tokenJSON = await tokenInfo.json();

			if (typeof tokenJSON.message != 'undefined' && tokenJSON.message.includes('success')) {
				cookies.set('shovelled-token', cookieToken);
				setLoggedIn([true, tokenJSON.name, tokenJSON.position, tokenJSON.id]);
				setLoading(false);
			} else {
				setLoggedIn(false);
				setLoading(false);
			}
		} else {
			setLoggedIn(false);
			setLoading(false);
		}
	}, []);

	const loadingIcon = <Loading />
	const loggedOutPageContent = <Login setLoggedIn={setLoggedIn} />;
	
	const loggedInPageContent = (
		<Router>
			<div className={open ? 'page-wrapper chiller-theme toggled' : 'page-wrapper chiller-theme'}>
				<NavMin accountInfo={loggedIn} open={open} setOpen={setOpen} />
				<AdminNav accountInfo={loggedIn} siteTitle={'Shovelled'} open={open} setOpen={setOpen} />            				
				<Switch>
					<Route path='/' exact>
						<Jobs accountInfo={loggedIn} />
					</Route>
					<Route path='/map'>
						<MapView />
					</Route>
					<Route path='/resources'>
						<Resources />
					</Route>
					<Route path='/billing'>
						<BillingInfo />
					</Route>
					<Route path='/users'>
						<Users />
					</Route>
					<Route path='/jobs'>
						<Jobs accountInfo={loggedIn} />
					</Route>
					<Route path='/analytics'>
						<Analytics accountInfo={loggedIn} />
					</Route>
					<Route path='/gallery'>
						<Gallery accountInfo={loggedIn} />
					</Route>
					<Route path='/reset'>
						<ResetData />
					</Route>
					<Route path='/profile'>
						<Profile accountInfo={loggedIn} />
					</Route>
				</Switch>
			</div>
		</Router>
	);

	const signInStatus = loggedIn[0] ? loggedInPageContent : loggedOutPageContent;

	return (
		<React.Fragment>
			{ loading ? loadingIcon : signInStatus }
		</React.Fragment>
	);
}

export default App;